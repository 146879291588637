(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* eslint-disable */
/**
 *  var map = new YaMaps2('ymaps-map', <?php echo json_encode($dealers)?>, {
 *    map: {
 *      center: [55.75399400, 37.62209300],
 *      zoom: 5,
 *    },
 *
 *    placemark: {
 *      iconLayout: 'default#image',
 *      iconImageHref: '/i/map-mark.png',
 *      iconImageSize: [30, 70]
 *    },
 *
 *    clusterer: {
 *      gridSize : 128,
 *      clusterIcons: [{
 *        href: '/i/map-mark.png',
 *        size: [30, 70]
 *      }]
 *    },
 *
 *    citySelector: '.js-dealers-city'
 *  });
 *
 */

;(function (GLOBAL) {
  'use strict';

  var defaults = {
    map: {
      type: 'yandex#map',
      center: [55.75399400, 37.62209300],
      zoom: 8,
      behaviors: ['drag', 'multiTouch', 'dblClickZoom']
    },

    placemark: {},

    clusterer: {
      gridSize: 64,
      groupByCoordinates: false,
      clusterDisableClickZoom: true,
      clusterOpenBalloonOnClick: true
    },

    citySelector: '.dealers-city',
    citySelect: '.js-city-selector'
  };

  /**
   * Конструктор карты
   *
   * @param elem {string}
   * @param data {Array}
   * @param options {Object}
   */
  GLOBAL.YaMaps2 = function (elem, data, options) {
    this.data = data;
    this.elem = elem;

    // Экстендим дефолтные настройки пользовательскими
    this.config = _deepExtend(defaults, options || {});
    this.markers = [];

    this.init();

    return this.map;
  };

  /**
   * Инициализируем карту
   */
  YaMaps2.prototype.init = function () {
    var citySelect = document.querySelector(this.config.citySelect);
    var ID = jQuery(citySelect).find(':selected').data('city');

    this.map = new ymaps.Map(this.elem, this.config.map);

    // Создаем кастомный лейаут для балуна кластера,
    this.customClusterLayout = ymaps.templateLayoutFactory.createClass(['{% for geoObject in properties.geoObjects %}', '{{ geoObject.properties.balloonContentBody|raw }}', '{% endfor %}'].join(''));

    // Добавляем кастомный лейаут в настройки кластера
    this.config.clusterer.clusterBalloonContentLayout = this.customClusterLayout;

    this.addEventHandlers();
    this.initClusterer(this.config.clusterer);

    if (ID) return this.createCityBalloon(ID);
  };

  /**
   * Инициализируем кластер
   */
  YaMaps2.prototype.initClusterer = function () {
    this.clusterer = new ymaps.Clusterer(this.config.clusterer);
    this.map.geoObjects.add(this.clusterer);

    return this.initMarkers();
  };

  /**
   * Инициализируем маркеры
   */
  YaMaps2.prototype.initMarkers = function () {
    var _this = this;
    var data = this.data;

    for (var i in data) {
      if (data[i].coordinates.length === 2) {
        _this.markers.push(_this.createMarker(data[i]));
      }
    }

    _this.clusterer.add(this.markers);
  };

  /**
   * Создаем маркеры
   *
   * @param data {Object}
   */
  YaMaps2.prototype.createMarker = function (data) {
    return new ymaps.Placemark(data.coordinates, {
      clusterCaption: data.dealer.name,
      balloonContentBody: this.getBalloonContent(data),
      city_position: data.city_position,
      city: data.city_id
    }, this.config.placemark);
  };

  /**
   * Создаем балун
   *
   * @param data {Object}
   */
  YaMaps2.prototype.getBalloonContent = function (data) {
    // TODO: сделать доступным в options
    var email = '';
    if (data.email != null && data.email.length > 0) email = '<p class="mail-icon"><a href="mailto:' + data.email + '">' + data.email + '</a></p>';
    return ['<div class="balloon-content">', '<p class="yellow bb">' + data.dealer.name + '</p>', email, data.address, '<br>', data.phone, '</div>'].join('');
  };

  /**
   * События
   */
  YaMaps2.prototype.addEventHandlers = function () {
    var _this = this;

    // Находим города
    var cityElems = document.querySelectorAll(this.config.citySelector);
    var citySelect = document.querySelector(this.config.citySelect);

    // Слушаем клики по городам
    cityElems.length && function () {
      for (var i = cityElems.length - 1; i >= 0; i--) {
        cityElems[i].addEventListener('click', function (ev) {
          ev.preventDefault();
          var ID = ev.target.getAttribute('data-city');

          _this.createCityBalloon(ID);
        });
      }
    }();

    // Изменение селекта с городами
    jQuery(citySelect).on('change', function () {
      _this.createCityBalloon(jQuery(this).find(':selected').val());
    });
  };

  /**
   * Создаем балун для выбранного города
   *
   * @param ID {string}
   */
  YaMaps2.prototype.createCityBalloon = function (ID) {
    var cityMarkers = [];

    for (var i in this.markers) {
      if (ID == 'all' || this.markers[i].properties.get('city') === ID) {
        cityMarkers.push(this.markers[i]);
      }
    }

    if (cityMarkers.length) this.showCityBalloon(cityMarkers);
  };

  /**
   * Отображаем маркеры, принадлежащие выбранному городу
   *
   * @param cityMarkers {Array}
   */
  YaMaps2.prototype.showCityBalloon = function (cityMarkers) {
    // var _this = this;
    // var zoom = 12;
    // var center = cityMarkers[0].geometry.getCoordinates();
    // var isShown = this.clusterer.getObjectState(cityMarkers[0]).isShown;

    var cityCollection = new ymaps.GeoObjectCollection();

    for (var i in cityMarkers) {
      cityCollection.add(cityMarkers[i]);
    }

    if (this.map.balloon.isOpen()) this.map.balloon.close();

    this.map.setBounds(ymaps.geoQuery(cityCollection).getBounds(), { checkZoomRange: true, zoomMargin: 10 }).then(function () {
      if (this.map.getZoom() > 17) {
        this.map.setZoom(17);
      }
    }, function (err) {}, this);

    // this.map.setCenter(center, zoom, { checkZoomRange: true });

    // // Если кластер в видимой части карты,
    // // просто показываем балун
    // if (isShown) {
    //    _showBalloon();
    // }
    //
    // // если нет, показываем балун после boundschange
    // else {
    //   this.map.events.once('boundschange', _showBalloon);
    // }
    //
    //
    // function _showBalloon() {
    //   var objectState = _this.clusterer.getObjectState(cityMarkers[0]);
    //
    //   if (objectState.isClustered) {
    //     objectState.cluster.state.set('activeObject', cityMarkers);
    //     _this.clusterer.balloon.open(objectState.cluster);
    //   }
    //
    //   else {
    //     cityMarkers[0].balloon.open();
    //   }
    // }
  };

  /**
   * _deepExtend
   *
   * @param dest {Object}
   * @param src {Object}
   */
  function _deepExtend(dest, src) {
    for (var prop in src) {
      if (_typeof(src[prop]) === "object" && src[prop] !== null && dest[prop]) {
        _deepExtend(dest[prop], src[prop]);
      } else {
        dest[prop] = src[prop];
      }
    }

    return dest;
  };
})(window);
/* eslint-enable */

},{}]},{},[1]);
